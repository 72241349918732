import React from "react"
import { AppProvider, Layout, Page, TextContainer } from "@shopify/polaris"
import { appName as getAppName } from "../helpers/app"

const PrivacyPage = () => {
  const appName = getAppName()
  return (
    <AppProvider i18n={{}}>
      <Page title={`${appName} Privacy Policy`}>
        <Layout>
          <Layout.Section>
            <TextContainer>
            {/* <p>{appName} Privacy Policy</p> */}

            <p>
              {appName} "the App” provides visualization of orders "the Service"
              to merchants who use Shopify to power their stores. This Privacy
              Policy describes how personal information is collected, used, and
              shared when you install or use the App in connection with your
              Shopify-supported store.
            </p>

            <p>Personal Information the App Collects</p>

            <p>
              When you install the App, we are automatically able to access
              certain types of information from your Shopify account:
            </p>
            <ol>
              <li>Read Order information</li>
              <li>Read Customer profiles</li>
              <li>Read Product information</li>
            </ol>

            <p>
              We collect personal information directly from the relevant
              individual, through your Shopify account, as well as using the
              following technologies: “Cookies” are data files that are placed
              on your device or computer and often include an anonymous unique
              identifier. For more information about cookies, and how to disable
              cookies, visit{" "}
              <a href="http://www.allaboutcookies.org">
                http://www.allaboutcookies.org
              </a>
              . “Log files” track actions occurring on the Site, and collect
              data including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps. “Web
              beacons,” “tags,” and “pixels” are electronic files used to record
              information about how you browse the Site.
            </p>

            <p>How Do We Use Your Personal Information?</p>

            <p>
              We use the personal information we collect from you and your
              customers in order to provide the Service and to operate the App.
              Additionally, we use this personal information to: Optimize or
              improve the App.
            </p>

            {/*
            <p>Sharing Your Personal Information</p>

            <p>
              We don't share anything or really collection any details, so...
            </p>
             */}

            <p>
              Finally, we may also share your Personal Information to comply
              with applicable laws and regulations, to respond to a subpoena,
              search warrant or other lawful request for information we receive,
              or to otherwise protect our rights.
            </p>

            <p>
              Data Retention When you place an order through the Site, we will
              maintain your Order Information for our records unless and until
              you ask us to delete this information.
            </p>

            <p>
              Changes We may update this privacy policy from time to time in
              order to reflect, for example, changes to our practices or for
              other operational, legal or regulatory reasons.
            </p>

            <p>
              Contact Us For more information about our privacy practices, if
              you have questions, or if you would like to make a complaint,
              please contact us by e-mail at support@k5technlogies.com or by
              mail using the details provided below:
            </p>
            <p>
              K5 Holdings LLC
              <br />
              Central, Oregon <br />
              541-316-7080
              <br />
              info@k5technologies.com
            </p>
                </TextContainer> 
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  )
}
export default PrivacyPage
